<template>
    <div class="half-slider relative w-full h-full">
        <agile ref="carousel" :nav-buttons="false" :infinite="false" @after-change="e => currentSlideIndex = e.currentSlide">
            <div v-for="(image, key) in images" :key="key" class="slide">
                <img :src="image.url" :alt="image.title" class="object-cover w-full h-full" loading="lazy" />
            </div>
        </agile>
        <button
            v-if="currentSlideIndex > 0"
            class="half-slider__prev left-4 md:left-6"
            :aria-label="ariaLabels.previous"
            @click="$refs.carousel.goToPrev()"
        />
        <button
            v-if="currentSlideIndex < images.length - 1"
            class="half-slider__next right-4 md:right-6 transform rotate-180"
            :aria-label="ariaLabels.next"
            @click="$refs.carousel.goToNext()"
        />
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'half-slider',

        components: {
            agile: VueAgile
        },

        props: {
            images: { type: Array, default: () => []},
            ariaLabels: {type: Object, required: true},
        },

        data(){
            return {
                currentSlideIndex: 0
            }
        }
    }
</script>

<style lang="scss">
    .half-slider {
        .agile, .agile__list, .agile__track, .agile__slides, .agile__slide {
            @apply h-full;
        }

        .half-slider__next, .half-slider__prev {
            @apply cursor-pointer absolute top-1/2 transform -translate-y-1/2 bg-cover bg-center;
            width: 4.875rem;
            height: 4.875rem;
            background-image: url('~@/static/images/icons/arrow-slider.svg');
        }

        .half-slider__next {
            @apply rotate-180;
        }
    }

</style>
