import "./assets/styles/main.scss";

import { createApp } from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import HeaderCustom from "@/app/components/HeaderCustom";
import HalfSlider from "@/app/components/HalfSlider";
import Slider from "@/app/components/Slider";
import ScrollIndicator from "@/app/components/ScrollIndicator";
import VueAgile from 'vue-agile'


const app = createApp({
    name: 'App',
    components: {
        CsrfInput,
        HeaderCustom,
        HalfSlider,
        Slider,
        ScrollIndicator
    }
})
    .use(VueAgile)
    .mount('#app');
