<template>
    <div class="slider-component">
        <div class="flex justify-between items-center w-full">
            <button
                class="slider-component__prev flex-shrink-0 relative z-10 -mr-5 md:mr-0"
                @click="$refs.carousel.goToPrev()"
                :aria-label="ariaLabels.previous"
            />
            <agile
                ref="carousel"
                :options="options" @after-change="e => currentSlideIndex = e.currentSlide"
                class="flex-grow-0 w-5/6 md:w-3/4"
            >
                <div v-for="(slide, key) in slides" :key="key" class="slide">
                    <img :src="slide.image.url" :alt="slide.image.title" loading="lazy" />
                </div>
            </agile>
            <button
                class="slider-component__next flex-shrink-0 -ml-5 md:ml-0 transform rotate-180"
                @click="$refs.carousel.goToNext()"
                :aria-label="ariaLabels.next"
            />
        </div>
        <div class="relative h-14 md:h-10 mt-4 md:mt-8 text-center">
            <p
                v-for="(text, index) in slides"
                :key="index"
                :class="{'opacity-100' : currentSlideIndex === index}"
                class="slider-component__legend h5 text-dark absolute left-1/2 transform -translate-x-1/2 opacity-0 w-full"
            >{{ text.caption }}</p>
        </div>
        <div class="flex justify-center mt-4">
            <button
                v-for="(dot, index) in slides"
                :key="index"
                :class="{'dot--selected': currentSlideIndex === index}"
                class="dot"
                @click="$refs.carousel.goTo(index)"
                :aria-label="`${ariaLabels.selectButton} ${index+1}`"
            ></button>
        </div>
    </div>
</template>

<script>
    import { VueAgile } from 'vue-agile'

    export default {
        name: 'slider',

        components: {
            agile: VueAgile
        },

        props: {
            slides: {type: Array, default: () => []},
            ariaLabels: {type: Object, required: true},
        },

        data(){
            return {
                currentSlideIndex: 0,

                options : {
                    centerMode: true,
                    navButtons: false,
                    dots: true,
                    infinite: false,
                    autoplay: false,
                    autoplaySpeed: 5000,
                    speed: 1000
                }
            }
        }
    }
</script>

<style lang="scss">
    .slider-component {
        .agile__list {
            @apply overflow-visible;
        }

        &__legend {
            transition: all 0.5s ease-out;
        }

        .dot {
            @apply w-2.5 h-2.5 border border-olive-light rounded-full mx-2;
            transition: all 0.5s ease-out;

            &--selected {
                @apply border-olive bg-olive;
            }
        }

        .agile__slide {
            @apply w-full;

            @screen md {
                @apply flex justify-center w-auto;
            }
        }

        .agile__slide img {
            @apply w-full;
            transition: all 0.6s ease-out;

            @screen md {
                @apply w-auto;
            }
        }

        .agile__slide:not(.agile__slide--active) img {
            transform: scale(0.9);
            filter: saturate(0%);
        }

        .agile__slide:not(.agile__slide--active) {
            @apply relative;
        }

        .agile__slide:not(.agile__slide--active)::after {
            content : '';
            @apply absolute w-full h-full inset-0 opacity-60 z-20;
            background-color : #FFFCF7;
        }

        .slider-component__next, .slider-component__prev {
            @apply w-10 h-10 cursor-pointer bg-cover bg-center;

            background-image: url('~@/static/images/icons/arrow-slider.svg');

            @screen md {
                width: 4.875rem;
                height: 4.875rem;
            }
        }
    }
</style>

