<template>
    <header class="header flex bg-mauve-light">
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full flex justify-between items-center lg:justify-start">
                    <a :href="homeUrl" class="relative">
                        <img v-if="logo" :src="logo.url" :alt="logo.alt" class="header__logo" />
                        <img v-if="negativeLogo" :src="negativeLogo.url" :alt="negativeLogo.alt" class="header__logo header__logo--negative absolute inset-0 opacity-0" />
                    </a>

                    <div class="header__burger-menu cursor-pointer lg:hidden" @click="toggleMenu">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                    <div ref="menuContainer" class="header__menu-container fixed inset-0 flex flex-col bg-olive text-white border-t border-white lg:relative lg:flex-row lg:bg-transparent lg:text-dark lg:border-none lg:flex-grow lg:justify-between">
                        <nav
                            v-if="menu"
                            class="flex-grow flex flex-col justify-center items-center lg:flex-row lg:justify-start"
                            :aria-label="ariaLabelMainMenu"
                        >
                            <a
                                v-for="(item, key) in menu"
                                :key="key"
                                :href="item.url"
                                class="header__menu-item font-argesta-display text-xl lg:text-sm xl:text-base lg:ml-5 xl:ml-12 whitespace-nowrap hover:no-underline hover:opacity-75 transition-all duration-200"
                            >{{ item.label }}</a>
                        </nav>

                        <div class="flex justify-center items-center py-6 border-t border-white lg:border-none lg:py-0">
                            <a v-if="phoneLink" :href="`tel:${phoneLink.linkedUrl}`" class="flex items-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="header__logo-tel mr-2 lg:hidden xl:block">
                                    <path d="m4.62 1.543-2.122.55a1.337 1.337 0 0 0-.985 1.475 12.827 12.827 0 0 0 10.918 10.92 1.336 1.336 0 0 0 1.476-.987l.55-2.12a1.33 1.33 0 0 0-.747-1.551L11.2 8.715l-.129-.05a1.332 1.332 0 0 0-1.45.433l-.701.876-.236-.156a9.813 9.813 0 0 1-2.502-2.502l-.157-.237.876-.7A1.33 1.33 0 0 0 7.286 4.8L6.17 2.29a1.33 1.33 0 0 0-1.55-.747zm.252.968a.33.33 0 0 1 .386.185l1.114 2.51a.33.33 0 0 1-.096.392l-1.222.978a.5.5 0 0 0-.119.644 10.812 10.812 0 0 0 3.845 3.845.5.5 0 0 0 .644-.12l.978-1.222a.332.332 0 0 1 .392-.094l2.51 1.114a.33.33 0 0 1 .185.386l-.55 2.12a.335.335 0 0 1-.37.248A11.826 11.826 0 0 1 2.503 3.43a.336.336 0 0 1 .247-.37l2.122-.549z" fill="#FFF" fill-rule="evenodd"/>
                                </svg>

                                <span>
                                    <span class="body3 block lg:text-olive">{{ phoneLink.customText }}</span>
                                    <span class="caption block lg:text-light lg:-mt-1">{{ baseline }}</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { gsap } from 'gsap';

    export default {
        name: 'header-custom',

        props: {
            logo: {type: Object, default: () => {}, required: true},
            negativeLogo: {type: Object, default: () => {}, required: true},
            menu: {type: Array, default: () => [], required: true},
            phoneLink: {type: Object, default: () => {}, required: true},
            baseline: {type: String, default: '', required: true},
            cta: {type: Object, default: () => {}, required: true},
            ariaLabelMainMenu: {type: String, default: '', required: true},
            homeUrl: {type: String, default: '', required: true},
        },

        data() {
            return {
                isMobileMenuOpened: false,
            }
        },

        methods: {
            toggleMenu(){
                this.isMobileMenuOpened = !this.isMobileMenuOpened;

                // Toggle the class opened
                this.$el.classList.toggle('opened');

                // Disable scroll when mobile menu is opened
                document.body.style.overflow = this.isMobileMenuOpened ? 'hidden': 'visible';

                // Show/hide the menu
                gsap.to(this.$refs.menuContainer, {
                    x : this.isMobileMenuOpened ? '0%' : '100%',
                    duration : 0.7,
                    ease : 'power3.out'
                });
            }
        }
    }
</script>

<style lang="scss">
    .header {
        padding: 0.375rem 0;
        transition: background-color 0.5s ease-out;

        @screen lg {
            padding: 0.8125rem 0;
        }

        &__logo {
            width: 3.2125rem;
            height: 3.2125rem;
            min-width: 3rem;
            min-height: 3rem;
            transition: opacity 0.3s ease-out;

            @screen xl {
                width: 3.8125rem;
                height: 3.8125rem;
            }
        }

        &__burger-menu {
            width: 1.75rem;

            div {
                @apply w-full h-px bg-olive;
                transition: all 0.3s ease-out;
                transform-origin: left;

                & + div {
                    @apply block mt-2;
                }
            }
        }

        &__menu-container {
            top: 63px;
            transform: translateX(100%);

            @screen lg {
                top: 0;
                transform: translateX(0%);
            }
        }

        &__menu-item:not(:first-child) {
            margin-top: 6vh;

            @screen lg {
                @apply mt-0;
            }
        }

        &__logo-tel path{
            @screen lg {
                fill: #3e7d63;
            }
        }

        &.opened {
            @apply fixed z-20 top-0 inset-x-0 bg-olive;

            @screen lg {
                @apply bg-mauve-light;
            }

            .header__logo {
                @apply opacity-0;

                &--negative {
                    @apply opacity-100
                }
            }

            .header__burger-menu {
                div:first-child {
                    @apply bg-white;
                    transform: rotate(45deg) translateY(-1px);
                }
                div:nth-child(2) {
                    opacity : 0;
                }
                div:last-child {
                    @apply bg-white;
                    transform: rotate(-45deg) translateY(1px);
                }
            }
        }
    }
</style>
