<template>
    <a href="#" class="scroll-indicator hidden md:flex md:flex-col md:items-center hover:no-underline" @click.prevent="scroll">
        <p class="body3 text-olive">{{ text }}</p>
        <div class="w-px h-8 bg-olive mt-4"></div>
    </a>
</template>

<script>
    import { gsap } from 'gsap';
    import { ScrollToPlugin } from "gsap/ScrollToPlugin";
    gsap.registerPlugin(ScrollToPlugin);

    export default {
        name: 'scroll-indicator',

        props: {
            text: {type: String, default: ''},
            container: {type: String, default: ''}
        },

        methods: {
            scroll(){
                const container = document.querySelector(`.${this.container}`);
                const offsetTop = container.getBoundingClientRect().top + window.pageYOffset;
                const containerHeight = container.clientHeight;

                gsap.to(window, {
                    scrollTo: offsetTop + containerHeight,
                    duration: 0.1,
                    ease: 'power1.out'
                })
            }
        }
    }
</script>
